export const ENDPOINTS = {
    articles: "/articles",
    forms: "/forms",
    modals: "/modals",
    pagesSimple: "/page-simples",
    projects: "/projects",
    projectsCategories: "/project-categories",
    reviews: "/reviews",
    socials: "/socials",
    common: "/common",
    footer: "/footer",
    header: "/header",
    navigation: "/navigation",
    pageArticle: "/page-article",
    pageArticles: "/page-articles",
    pageHome: "/page-home",
    pageNotFound: "/page-not-found",
    pageProject: "/page-project",
    pageProjects: "/page-projects",
    pageReviews: "/page-reviews",
    formsSend: "/ezforms/submit",
    telegramSendMessage: "/telegram-bot-strapi/send-message",
};
