import { forwardRef } from "react";

import { ElProps } from "@Shared/types";
import { Icon, IconProps, Text, TextProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type RatingPropsClassNamesModel = {
    value?: string;
    wrapper?: string;
    stars?: string;
    icon?: string;
    caption?: string;
};

export type RatingProps = ElProps<"div"> & {
    classNames?: RatingPropsClassNamesModel;
    value: number;
    valueMax?: number;
    valueProps?: Omit<TextProps, "className" | "children">;
    iconProps?: Omit<IconProps, "className" | "children">;
    caption?: string;
    captionProps?: Omit<TextProps, "className" | "children">;
};

export const Rating = forwardRef<HTMLDivElement, RatingProps>(
    (
        { className, classNames, value, valueMax = 5, valueProps, iconProps, caption, captionProps, ...restProps },
        ref
    ) => {
        return (
            <div ref={ref} className={twcx("flex items-center gap-2", className)} {...restProps}>
                <Text className={classNames?.value} variant="h4" weight={600} {...valueProps}>
                    {Math.min(value, valueMax).toFixed(1)}
                </Text>

                <div className={twcx("flex flex-col items-start gap-0.5", "lg:gap-1", classNames?.wrapper)}>
                    <div className={twcx("flex items-center text-gray-05", classNames?.stars)}>
                        {Array(valueMax)
                            .fill(null)
                            .map((_, index) => (
                                <Icon
                                    key={index}
                                    className={twcx(
                                        "max-lg:text-[20px]",
                                        {
                                            "text-[#F3BA3B]": index < value,
                                        },
                                        classNames?.icon
                                    )}
                                    k="star"
                                    size="md"
                                    {...iconProps}
                                />
                            ))}
                    </div>

                    {caption && (
                        <Text className={classNames?.caption} variant="p3" weight={600} {...captionProps}>
                            {caption}
                        </Text>
                    )}
                </div>
            </div>
        );
    }
);
